.blink_me {
  animation: blinker 1s linear infinite;
}
.avatar {
  vertical-align: middle;
  width: 90px;
  height: 90px;
  border-radius: 50%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn,
.jumbotron,
 .nav:hover {
  border-radius: 10px;
}
.emailInput:focus {
  outline-width: 0;
}
body {
  
  background: #ffffff;
}
.validate{
  color:red;
}
.footer{
  position: fixed;
  bottom: 0px;
 
  left: 0px;
  right:0px;
  background-color: #EE1355;
}
.info-color
{

background:#EE1355;
color:#ffffff
}

.btn-default{
  border-color: coral;
  margin-right: 5px;
}

.error{
  color: red;
}

.table-container {
  overflow: auto;
  max-height: 160px;
 
}

th {
  position: sticky;
  top: 0;
  background: white;
}

/*Gallery Container*/
.gallery-container {
  overflow: hidden;
  position: relative;
  width:100%;
  
}

.gallery-container::after {
  padding-top: 50%;
  display: block;
  content: '';
}

.gallery-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 278px;
  height: 185px;
}

@media only screen and (max-width: 600px) {
  .gallery-container {
    overflow: hidden;
    position: relative;
    width:100%;
    margin-top: 10px;
    
  }
  
  .gallery-container::after {
    padding-top: 50%;
    display: block;
    content: '';
  }
  
  .gallery-container img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
/*Gallery container end*/